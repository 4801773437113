import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@core/services/http/base-http.service';
import { Page } from '@core/data/model/page';
import { PullRequestFilter } from '@core/data/model/dto/pull-request-filter';
import { PullRequestStatusType } from '@core/data/type/pull-request-status.type';
import { PullRequest } from '@core/data/model/pull-request';
import { tap } from 'rxjs/operators';
import { Developer } from '@core/data/model/developer';
import { Incident } from '@core/data/model/incident';
import { IncidentFilter } from '@core/data/model/dto/incident-filter';
import { IncidentStatusType } from '@core/data/type/incident-status.type';
import { IncidentPriorityType } from '@core/data/type/incident-priority.type';

@Injectable()
export class IncidentService extends BaseHttpService {

  constructor(protected override httpClient: HttpClient) {
    super('itsm-service/incidents', httpClient);
  }

  searchIncidents(
    statuses: IncidentStatusType[],
    priorities: IncidentPriorityType[],
    searchPhrase?: string,
    page = 0,
    size = 30,
    sortKey = 'openedDate',
    sortDir = 'asc'
  ): Observable<Page<Incident>> {
    const filter: IncidentFilter = {
      statuses: statuses,
      priorities: priorities,
      searchPhrase: searchPhrase
    };
    return this.post<Page<Incident>>(`/search?page=${page}&size=${size}&sort=${sortKey},${sortDir}`, filter);
  }
}
