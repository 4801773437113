import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@core/services/http/base-http.service';
import { Page } from '@core/data/model/page';
import { PullRequestFilter } from '@core/data/model/dto/pull-request-filter';
import { PullRequestStatusType } from '@core/data/type/pull-request-status.type';
import { PullRequest } from '@core/data/model/pull-request';
import { tap } from 'rxjs/operators';
import { Developer } from '@core/data/model/developer';

@Injectable()
export class PullRequestService extends BaseHttpService {

  constructor(protected override httpClient: HttpClient) {
    super('scm-service/pull-requests', httpClient);
  }

  searchPullRequests(
    status: PullRequestStatusType,
    repositoryResourceIds?: string[],
    authorDeveloperIds?: string[],
    page = 0,
    size = 30,
    sortKey = 'openedDate',
    sortDir = 'asc'
  ): Observable<Page<PullRequest>> {
    const filter: PullRequestFilter = {
      status: status,
      repositoryResourceIds: repositoryResourceIds,
      authorDeveloperIds: authorDeveloperIds
    };
    return this.post<Page<PullRequest>>(`/search?page=${page}&size=${size}&sort=${sortKey},${sortDir}`, filter);
  }
}
