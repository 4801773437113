import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@core/services/http/base-http.service';
import { Page } from '@core/data/model/page';
import { IssueStatusType } from '@core/data/type/issue-status.type';
import { Issue } from '@core/data/model/issue';
import { IssueFilter } from '@core/data/model/dto/issue-filter';
import { tap } from 'rxjs/operators';
import { Developer } from '@core/data/model/developer';

@Injectable()
export class IssueService extends BaseHttpService {

  constructor(protected override httpClient: HttpClient) {
    super('task-service/issues', httpClient);
  }

  searchIssues(
    statuses: IssueStatusType[],
    sortByAnalysisResults = false,
    projectPlatformResourceIds?: string[],
    assigneeDeveloperIds?: string[],
    searchPhrase?: string,
    page = 0,
    size = 30,
    sortKey = 'openedDate',
    sortDir = 'asc'
  ): Observable<Page<Issue>> {
    const filter: IssueFilter = {
      statuses: statuses,
      sortByAnalysisResults: sortByAnalysisResults,
      projectPlatformResourceIds: projectPlatformResourceIds,
      assigneeDeveloperIds: assigneeDeveloperIds,
      searchPhrase: searchPhrase
    };
    return this.post<Page<Issue>>(`/search?page=${page}&size=${size}&sort=${sortKey},${sortDir}`, filter).pipe(
      tap(result => {
        result.content = result.content.map(issue => {
          if (issue.assigneeDeveloper) {
            issue.assigneeDeveloper = Object.assign(new Developer(), issue.assigneeDeveloper);
          }
          if (issue.reporterDeveloper) {
            issue.reporterDeveloper = Object.assign(new Developer(), issue.reporterDeveloper);
          }
          return issue;
        })
      })
    );
  }
}
